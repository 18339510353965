import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import Modal from "@/components/Modal"
import { SocketContext } from "@/context/socket"
import { addAI } from "@/helpers/computeruse"
import { defaultPopperProps } from "@/helpers"
import { assignIsAddingVNC } from "@/store/slices/appSlice"
import { FormControlLabel, Tooltip, Radio, RadioGroup } from "@mui/material"
import cn from "classnames"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import validator from "validator"
const { isURL } = validator

type Props = {
  addDisabled?: boolean
  onClose: () => void
}

export default function AddComputerUseModal({ addDisabled, onClose }: Props) {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const spaceId = useSelector((state: RootState) => state.space.spaceId)
  const roomId =
    useSelector((state: RootState) => state.room.data?._id) || spaceId
  const apps = useSelector((state: RootState) => state.space.apps)
  const [url, setUrl] = useState("")
  const [browser, setBrowser] = useState("firefox")
  const [vncName, setVncName] = useState("")
  const isAddingVNC = useSelector((state: RootState) => state.app.isAddingVNC)
  const userId = useSelector((state: RootState) => state.user.data?._id)

  async function handleAddComputerUse() {
    // const finalURL = resolveValidHttpURL(url)
    // if (!finalURL) {
    //   return
    // }
    dispatch(assignIsAddingVNC(true))

    const addingToast = toast(
      `Adding ComputerUse. Please do not refresh the page.`,
      {
        toastId: `adding-computeruse`,
        autoClose: false,
        closeOnClick: false,
        theme: "dark",
      },
    )

    try {
      // const { hostname } = new URL(finalURL)
      const payload = {
        roomId: roomId!,
        fileName: "computeruse",
        title: vncName || "ai-agent",
        url: "https://anthropic.com",
      }

      const newRoomObj: any = await addAI(payload, {
        onFinally: () => {
          onClose()
          socket.emit(SocketMessageType.GetRoom, {
            senderId: null,
            roomId,
          })
        },
        onSuccess: () => {
          setUrl("")
          setVncName("")
        },
      })
    } catch (error: any) {
      toast.error(
        `Failed to add computeruse app: ${
          error.msg || error.message || `Server Error`
        }`,
        {
          autoClose: 4000,
          style: {
            width: "fit-content",
            whiteSpace: "pre-wrap",
          },
        },
      )
      console.error("Failed to add computeruse app", error)
    } finally {
      dispatch(assignIsAddingVNC(false))
    }

    toast.dismiss(addingToast)
  }

  // const submitDisabled = resolveValidHttpURL(url) === false
  const disabled = addDisabled
  // const disabled = addDisabled || submitDisabled
  const msg = addDisabled
    ? "You have reached your limit of cobrowse tiles"
    : "Please provide a valid URL"

  if (isAddingVNC) {
    return null
  }

  return (
    <Modal
      className="relative w-[400px] flex flex-col"
      title="Add AI Agent"
      onClose={onClose}
      info="Add a URL to allow collaborative web browsing for the specified site. When tile is first added, only the owner of the space will be allowed to interact. To grant control privileges to additional users, use the Share Control button on the toolbar."
    >
      <div className="relative flex flex-col items-center justify-center">
        <span className="text-typegray text-xs">
          Click to add AI Agent
        </span>
        <br />
        <br />
        {/* <RadioGroup
          value={browser}
          onChange={(e) => setBrowser(e.target.value)}
          row
        >
         <FormControlLabel
            value="chrome"
            control={<Radio />}
            label="Chrome"
            labelPlacement="end"
          />
          <FormControlLabel
            value="firefox"
            control={<Radio />}
            label="Firefox"
            labelPlacement="end"
          />
          <FormControlLabel
            value="chromium"
            control={<Radio />}
            label="Chromium"
            labelPlacement="end"
          />
        </RadioGroup> */}
        <Tooltip title={disabled && msg} PopperProps={defaultPopperProps}>
          <span>
            <button
              className={cn("relative w-[40px] h-[40px] flex-center", {
                "cursor-not-allowed": disabled,
              })}
              onClick={handleAddComputerUse}
              disabled={isAddingVNC || disabled}
            >
              <Icon
                className={cn({
                  "fill-cloudy": disabled,
                  "fill-lavender hover:fill-accentgreen": !disabled,
                })}
                iconName={IconNameProp.Add}
                size={16}
              />
            </button>
          </span>
        </Tooltip>
      </div>
    </Modal>
  )
}

///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////

function resolveValidHttpURL(url: string) {
  try {
    const isValid = isURL(url, {
      protocols: ["http", "https"],
    })
    if (!isValid) return false

    let finalURL = url
    if (!finalURL.startsWith("http://") && !finalURL.startsWith("https://")) {
      finalURL = `https://${finalURL}`
    }
    return finalURL
  } catch (err) {
    return false
  }
}

function isValidHttpUrl(url: string) {
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === "http:" || newUrl.protocol === "https:"
  } catch (err) {
    return false
  }
}
