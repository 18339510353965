import CenteredLoader from "@/components/CenteredLoader"
import { CobrowseType } from "@/TYPES/common"
import cn from "classnames"
import { useState, useEffect, useRef } from "react"
import Loader from "@/components/Loader"
import { getSiteResponse } from "@/helpers"
import CloseIcon from "@mui/icons-material/Close"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import AlertTitle from "@mui/material/AlertTitle"

type Props = {
  app: CobrowseType
  active: boolean
  isMain: boolean
}

export default function EmbedTile({ app, active, isMain = false }: Props) {
  const iframe_ref = useRef<HTMLIFrameElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const src = app.src

  const iframeStateHandler = (event: any) => {
    window.log("iframeStateHandler", "highlight", event.origin)
  }

  const onLoadHandler = async (evt) => {
    setIsLoading(false)
    const url = src
    try {
      const result = await getSiteResponse({
        url,
      })
      const data = result?.data
      if (!result.status || !data) return

      const headers = data.headers
      const body = data.body
      const xFrameOptions = headers["x-frame-options"]
      if (!xFrameOptions) return
      const xfo = xFrameOptions.toLowerCase()
      const likelyNotAllowed = (() => {
        if (xfo === "deny") return true
        if (xfo === "sameorigin") {
          const urlObj = new URL(url)
          const { origin: iframOrigin } = urlObj
          const origin = window.location.origin
          if (iframOrigin !== origin) return true
        }
        return false
      })()
      if (likelyNotAllowed) {
        setErrorMsg(
          `The site "${url}" does not allow embedding. Please add it as Cobrowse if you wish to interact with this site.`,
        )
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      // setIsLoading(false)
    }
  }

  return (
    <>
      {errorMsg && isMain && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={() => {
              setErrorMsg("")
            }}
          >
            {/* <AlertTitle>ALERT</AlertTitle> */}
            {errorMsg}
          </Alert>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            width: "100%",
            height: "100%",
            background: "rgba(44, 44, 47, 1)",
          }}
        >
          {/* <CenteredLoader /> */}
        </div>
      )}
      <iframe
        ref={iframe_ref}
        title={app.name}
        className={cn("w-full h-full bg-transparent", {
          "pointer-events-none": !active,
        })}
        src={app.src}
        allowFullScreen
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        onLoad={onLoadHandler}
      />
    </>
  )
}
