import { SocketMessageType } from "@/TYPES/common"
import { socketConnection as socket } from "@/context/socket"
import { getRoom } from "@/helpers"
import $store from "@/store"

export function getComputerUseBackendURL() {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_NEW_BACKEND_URL =
    env.REACT_APP_NEW_BACKEND_URL || import.meta.env.REACT_APP_NEW_BACKEND_URL

  if (!REACT_APP_NEW_BACKEND_URL) {
    const msg = "REACT_APP_BACKEND_URL not set"
    throw new Error(msg)
  }
  const backendURL = (
    REACT_APP_NEW_BACKEND_URL.endsWith("/")
      ? REACT_APP_NEW_BACKEND_URL
      : `${REACT_APP_NEW_BACKEND_URL}/`
  ).trim()
  const computeruseURL = new URL("computeruse", backendURL)
  return computeruseURL.href
}

export function getComputerUseIDFromURL(urlString: string) {
  const url = new URL(urlString)
  const pathSegments = url.pathname.split("/").filter((segment) => segment)
  return pathSegments[pathSegments.length - 1]
}

interface AddAIResult {
  status: boolean
  msg: string
  data?: {
    computeruseURL: string
    deployment: any
    configmap: any
    service: any
    ingressroute: any
    middleware: any
  }
}

interface AddAIParam {
  roomId: string
  fileName: string
  title: string
  url: string
  browser?: string
}

export function addAI(param: AddAIParam, callbacks?: any) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  const browser = "anthropic"

  return new Promise(async (resolve, reject) => {
    if (!param) {
      console.error("addAI failed - invalid param", param)
      reject("addAI failed - invalid param")
      return
    }
    try {
      //@@ First create ComputerUse in k8 @@//
      const addURL = getComputerUseBackendURL()
      const addResponse = await fetch(addURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: param.url,
          browser,
        }),
      })
      const addResult: AddAIResult = await addResponse.json()
      if (!addResult.status) {
        reject(addResult)
        console.error("addAI() failed - status is false", addResult)
        return
      }

      const addData = addResult.data
      if (!addData || !addData.computeruseURL) {
        reject(addData)
        console.error("addAI failed - invalid data", addData)
        return
      }
      console.log("addAI add ComputerUse - success", addData)
      const { computeruseURL } = addData

      //@@ Then add ComputerUse in DB @@//
      const dbResponse = await fetch(`${REACT_APP_API_SERVER}/cobrowse-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomId: param.roomId,
          src: computeruseURL,
          type: "computeruse",
          title: param.title,
          fileName: param.fileName,
          browser,
        }),
      })
      const dbResult = await dbResponse.json()
      if (!dbResult.status || !dbResult.data) {
        reject(dbResult)
        console.error("addAI failed on 2nd fetch result2", dbResult)
        return
      }
      const dbData = dbResult.data
      const newRoom = dbData.value
      console.log("addAI() add database - success", newRoom)

      callbacks?.onSuccess?.()

      resolve(newRoom)
    } catch (error) {
      reject(error)
      console.error("addAI() failed", error)
    } finally {
      getRoom(param.roomId)
      callbacks?.onFinally?.()
    }
  })
}

interface RemoveAIParam {
  roomId: string
  tileId: string
  url: string
}

export async function removeAI(param: RemoveAIParam, callbacks?: any) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  console.log("removeAI()", param)
  if (!param || !param.url || !param.roomId || !param.tileId) {
    console.error("removeAI failed - invalid param", param)
    return
  }
  try {

    // const fetchURL = getComputerUseBackendURL()
    const fetchURL = env.REACT_APP_NEW_BACKEND_URL || import.meta.env.REACT_APP_NEW_BACKEND_URL

    //@@ Deleting K8 resources @@//
    const fetchHandle = fetch(`${fetchURL}/computeruse`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: param.url,
        computeruseId: param.tileId,
      }),
    })

    const response = await fetchHandle
    const removeResourceResult = await response.json()

    //@@ If resource exists but was unable to delete it @@//
    if (removeResourceResult.type !== "not-found") {
      if (!removeResourceResult.status || !response.ok) {
        console.error("removeAI failed!", removeResourceResult)
        return
      }
    }

    console.log(
      "Removing AI deployment succeeded or does not exist",
      removeResourceResult,
    )

    //@@ Deleting DB data @@//
    const response2 = await fetch(`${REACT_APP_API_SERVER}/cobrowse-data`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tileId: param.tileId,
        roomId: param.roomId,
      }),
    })

    if (!response2.ok) {
      console.error("removeAI failed to remove tile from DB", response2)
      return
    }
    const result2 = await response2.json()
    if (!result2.status || !result2.data) {
      console.error(
        "removeAI failed to remove tile from DB but fetch was ok",
        result2,
      )
      return
    }

    console.log(`successfully removed tile from DB`, result2)

    callbacks?.onSuccess?.()

    return result2
  } catch (error: any) {
    console.error("removeAI failed;;;;;;;;;;;;", error)
    callbacks?.onError?.({
      msg: error?.message || "Removing ComputerUse failed",
    })
    return error
  }
}

//@@ Sets AI (ComputerUse) tile state so that so that it does not show loading UI anytime ComputerUse tile refreshes @@//
export async function setComputerUsePodPhase(param) {
  const phase = param?.phase
  const tileId = param?.tileId
  const roomId = param?.roomId

  if (!phase || !tileId || !roomId) {
    console.error("setComputerUsePodPhase() not triggered - invalid param:", param)
    return
  }

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  const fetchURL = `${REACT_APP_API_SERVER}/cobrowse-data`
  try {
    const fetchHandle = fetch(fetchURL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phase,
        tileId,
        roomId,
      }),
    })

    const response = await fetchHandle
    if (!response.ok) {
      console.error("setComputerUsePodPhase() failed..", response)
      return
    }
    const result = await response.json()
    if (!result.status) {
      console.error("setComputerUsePodPhase() failed!", result)
      return
    }
    const data = result.data
    console.log("setComputerUsePodPhase() - success", data)
    return data
  } catch (error) {
    console.error("setComputerUsePodPhase() catch error:", error)
    return error
  }
}

export interface SetComputerUseTileAccessParam {
  action: string
  tileId: string
  targetUserId: string
  isGuest?: boolean
  grantedAt?: string
}
export function setComputerUseTileAccessAsync(param: SetComputerUseTileAccessParam) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const roomId = storeState.room?.data?._id || storeState.space?.spaceId
  if (!roomId || !REACT_APP_API_SERVER) {
    const msg = "No roomId or REACT_APP_API_SERVER"
    console.error(msg)
    return Promise.reject(msg)
  }

  const fetchURL = `${REACT_APP_API_SERVER}/v1/room/cobrowse/access`
  const action = param.action
  const targetUserId = param.targetUserId

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(fetchURL, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      })
      const result = await response.json()

      resolve(result)

      if (socket && socket.connected) {
        socket.emit(SocketMessageType.GetRoom, {
          roomId,
        })
      }
    } catch (err: any) {
      console.error(err)
      reject(err.message || err)
    }
  })
}
